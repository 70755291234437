<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Modifier une marque",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Modifier une marque",

      typeForm: {
        uuid: "",
        name: "",
        description: "",
      },
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/",
        },
        {
          text: "Marques des equipements",
          href: "/",
        },
        {
          text: "Modification",
          active: true,
        },
      ],
    };
  },

  validations: {
    typeForm: {
      name: { required, maxLength: maxLength(64) },
    },
  },

  created() {},

  mounted() {
      this.fetchTypeData();
  },

  methods: {
    fetchTypeData() {
      this.$http
        .post("/infrastructure/equipment_brands/getSingleBrandDetails", {
          uuid: this.$route.params.uid,
        })
        .then((res) => {
          var status = res.data.original.status;
          var type = res.data.original.data;
          switch (status) {
            case 200:
                this.typeForm.uuid = type.uuid;
                this.typeForm.name = type.name;
                this.typeForm.description = type.description;
              break;

            case 404:
                this.$toast.warning(res.data.original.msg);
              break;

            case 500:
                this.$toast.error(res.data.original.msg);
              break;
          }
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/infrastructure/equipment_brands/update", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "settings.equipment_brands.index" });
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              break;

              case 404:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="typeForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.typeForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.typeForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeForm.name.required">La désignation est obligatoire.</span>
                      <span v-if="!$v.typeForm.name.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Description</label>
                    <input
                      id="projectname"
                      v-model="typeForm.description"
                      type="text"
                      class="form-control"
                      
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
